<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="采购单ID" >
                <el-input v-model="search.caiGouDanID" size="small" />
            </x-search-item>
            <x-search-item label="商品名称" >
                <el-input v-model="search.shangPinMC" size="small" />
            </x-search-item>
            <x-search-item label="条码" >
                <el-input v-model="search.tiaoMa" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="caiGouDanID" label="采购单ID" />
            <el-table-column width="180" prop="shangPinMC" label="商品名称" />
            <el-table-column width="180" prop="shangPinID" label="商品ID" />
            <el-table-column width="180" prop="guiGe" label="规格" />
            <el-table-column width="180" prop="huoHao" label="货号" />
            <el-table-column width="180" prop="tiaoMa" label="条码" />
            <el-table-column width="180" prop="fenLei" label="分类" />
            <el-table-column width="180" prop="danWei" label="单位" />
            <el-table-column width="180" prop="shengChanRQ" label="生产日期" />
            <el-table-column width="180" prop="baoZhiQiTian" label="保质期（天）" />
            <el-table-column width="180" prop="caiGouSL" label="采购数量" />
            <el-table-column width="180" prop="ruKuSL" label="入库数量" />
            <el-table-column width="180" prop="jinHuoJiaHeJi" label="进货价合计" />
            <el-table-column width="180" prop="jinHuoJia" label="进货价" />
            <el-table-column width="180" prop="guiGeJiShu" label="规格基数" />
            <el-table-column width="180" prop="dianNeiLSJ" label="店内零售价" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/cggl/CaiGouDanXiangQing";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/cggl/cgspmx/CaiGouDanXiangQingDetail";

    export default {
        name: "CaiGouDanXiangQingQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = service.query;
            return {
                search: {
                    caiGouDanID: '',
                    shangPinMC: '',
                    tiaoMa: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>